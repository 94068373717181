/*
 *   File :  portfolio-goals.js
 *   Author URI : www.evoqins.com
 *   Description : Table component which lists invested goals in the UI
 *   Integrations : null
 *   Version : v1.0
 *   Created : 23-10-2023
 */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataTable } from "../Form-elements"
import { EmptyScreen } from "../Other";

import Icon from "../Icon/icon";
import Colors from "../../Styles/color.module.scss"

const CUSTOM_STYLES = {
    headRow: {
        style: {
            fontSize: "16px",
            lineHeight: "18px"
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    rows: {
        style: {
            '&:last-child': {
                marginBottom: "12.875rem"
            },
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
        }
    }
};

const PORTFOLIO_STYLES_MOBILE = {
    headRow: {
        style: {
            paddingRight: "0px",
            fontSize: "14px",
            paddingBottom: "8px !important",
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'end',
                with:'100%'
            },
        },
    },
    cells: {
        style: {
            justifyContent: "end",
            '&:first-child': {
                padding: "0px 0 0px 0px !important"
            },
            '&:last-child': {
                padding: "0px 0px 0px 0 !important"
            },
        }
    }
};

const PortfolioGoalsTable = (props) => {

    const navigator = useNavigate();

    const [showArrow, setShowArrow] = useState(0);
    const [investedGoals, setInvestedGoals] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    useEffect(() => {
        setInvestedGoals(props.data);
    }, [props.data]);

    const GOAL_COLUMNS = [
        {
            name: 'Goal',
            selector: row => row['name'],
            sortable: true,
            width: "450px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray" />
                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 bg-white">{row['name']}</p>
                </div>
        },
        {
            name: 'Invested amount',
            selector: row => row['invested_amount'],
            sortable: true,
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current amount',
            selector: row => row['current_amount'],
            sortable: true,
            // width: "150px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Returns',
            selector: row => row['returns'],
            sortable: true,
            cell: row =>
                <p className={`${row['returns'] < 0 ? "color-red" : "color-sea-green"
                    } e-inter-medium e-font-14 line-height-18px mb-0`}
                    onClick={() => _handleCardClick(row)}>
                    {
                        row['returns'].toLocaleString("en-IN") == 0 ? '-'
                            :
                            <>
                                ₹{row['returns'].toLocaleString("en-IN")}
                                ({row['returns_percentage'].toLocaleString("en-IN")}%)
                            </>
                    }
                </p>
        },
        {
            name: '',
            sortable: true,
            width: "24px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>
        }

    ];

    const GOAL_COLUMNS_MOBILE = [
        {
            name: 'Goal',
            selector: row => row['name'],
            sortable: true,
            width: "60%",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <p className="color-black e-poppins-medium e-font-12 line-height-16px mb-0 bg-white">{row['name']}</p>
                </div>
        },
        {
            name: <div className="d-flex justify-content-end w-100">Current(Invested)</div>,
            selector: row => row['current_amount'],
            sortable: false,
            width: '40%',
            cell: (row, index) =>
                <div className="text-end  w-100">
                    <p className={`color-black e-poppins-semi-bold e-font-12 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}
                        onClick={() => _handleCardClick(row)}>
                        <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
                    <p className={`color-black e-poppins-medium e-font-12 line-height-18px mb-0 color-black`}
                        onClick={() => _handleCardClick(row)}>
                        <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
                    <p className={`${row['returns'] < 0 ? "color-red" : "color-sea-green"
                        } e-inter-medium e-font-12 line-height-18px mb-0`}
                        onClick={() => _handleCardClick(row)}>
                        {
                            row['returns'] == 0 ? '-'
                                :
                                <>
                                    ₹{row['returns'].toLocaleString("en-IN")}
                                    ({row['returns_percentage'].toLocaleString("en-IN")}%)
                                </>

                        }

                    </p>
                </div>
        }
    ];

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _handleCardClick = (row) => {
        props.onClick(row.id);
    }

    return (
        <>
            {
                investedGoals.length == 0 ?
                    <div className="col-12 text-center mt-5 pb-4">
                        <EmptyScreen image={require('../../Assets/Images/nodata/sip.svg').default}
                            title="Not yet done your goal-based planning?"
                            description="Plan your future by investing in your life goals."
                            buttonLabel="Explore Life Goals"
                            onPress={() => navigator('/goal')} />
                    </div>
                    :
                    <div className="e-mobile-table mt-sm-0 mt-2">
                        <DataTable columns={screenWidth > 576 ? GOAL_COLUMNS : GOAL_COLUMNS_MOBILE}
                            data={investedGoals}
                            pagination={false}
                            CUSTOM_STYLES={screenWidth > 576 ? CUSTOM_STYLES : PORTFOLIO_STYLES_MOBILE}
                            onRowMouseEnter={_handleRowHover}
                            onRowMouseLeave={_handleRowRemoveHover}
                            rowClick={_handleCardClick} />
                    </div>
            }
        </>
    )
}

export default PortfolioGoalsTable