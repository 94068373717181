/*
 *   File : list.js
 *   Author URI : https://evoqins.com
 *   Description : Explore page
 *   Integrations : NA
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { InvestmentHeader } from "../../Component/Header";
import { SelectDropDown } from "../../Component/Form-elements";
import Footer from "../../Component/Footer/footer";
import { ExploreFilterPopup } from "../../Component/Popups";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { FundTable } from "../../Component/Table";
import Icon from "../../Component/Icon/icon";

import { InvestGoalModal, PaymentStatusModal, MfInProcessStatus } from "../../Component/Modal";
import { CustomLoader } from "../../Component/Other";
import Store from "../../Store";
import APIService from "../../Service/api-service";
import { _getMFInvestmentSummary } from "../../Helper/api";
import { EmptyScreen } from "../../Component/Other";
import { _getDayOfMonth } from "../../Helper";

import NoData from '../../Assets/Images/nodata/explore.svg';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Explore', url: null }
];

const Explore = () => {

    const navigator = useNavigate();
    const location = useLocation();
    const filterRef = useRef({
        minimum_investment: null,
        fund_category: [null],
        fund_type: [null],
        amc: [null],
        theme: [null],
        rating: null,
        search_text: '',
        filter_by: null,
        filter_by_tag: "",
        category_name: "",
        sort_by: { value: 5, label: 'Returns - High to Low' },
        page_num: 1
    });
    const preloadFunds = useRef(null);

    const HEADER_FILTER = useSelector((state) => state.headerFilerToggle.SHOW_SEARCH_SUGGESTION);
    const ACTIVE_MF_FILTERS = useSelector(state => state.generalData.MF_FILTERS);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [sortBy, setSortBy] = useState({ value: 5, label: 'Returns - High to Low' });
    const [showFilter, setShowFilter] = useState(false);
    const [showInvestmentModal, setShowInvestModal] = useState({});
    const [filterData, setFilterData] = useState({});
    const [sortByData, setSortByData] = useState([]);
    const [fundList, setFundList] = useState([]);
    const [investmentAmount, setInvestmentAmount] = useState(null);
    const [fundCategory, setFundCategory] = useState([null]);
    const [fundType, setFundType] = useState([null]);
    const [amc, setAmc] = useState([null]);
    const [theme, setTheme] = useState([null]);
    const [rating, setRating] = useState(null);
    const [filterBy, setFilterBy] = useState(null);
    const [pageNumber, setPageNumber] = useState(ACTIVE_MF_FILTERS.page_num !== undefined ? ACTIVE_MF_FILTERS.page_num : 1);
    const [searchQuery, setSearchQuery] = useState("");
    const [initialRender, setInitialRenderStatus] = useState(false);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [filterRender, setFilterRender] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchQueryChange, setSearchQueryChange] = useState(false);
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [filtersSelected, setFiltersSelected] = useState(0);
    const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [quickInvestTagName, setQuickInvestTagName] = useState("");
    const [categoryName, setCategoryName] = useState('');
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);
    // Memoized values
    const memoizedData = useMemo(() => {
        return {
            fundList
        };
    }, [fundList]);


    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getFilterData();
    }, []);


    // filter and table header make as fixed while scrolling
    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.pageYOffset;

    //         let filter = document.getElementById("filter-section");
    //         let table_title = document.getElementsByClassName('rdt_TableHead')[0];

    //         if (filter && table_title) {
    //             if (currentScrollPos <= 20) {
    //                 filter.classList.remove('e-fixed-filter-section');
    //                 table_title.classList.remove('e-fixed-table-header');
    //             } else {
    //                     filter.classList.add('e-fixed-filter-section');
    //                     table_title.classList.add('e-fixed-table-header');
    //             }
    //         }

    //         setPrevScrollpos(currentScrollPos);
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         // Cleanup: Remove the event listener when the component is unmounted
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [prevScrollpos]);

    useEffect(() => {
        filterRef.current.minimum_investment = investmentAmount;
    }, [investmentAmount]);

    useEffect(() => {
        filterRef.current.fund_category = fundCategory;
    }, [fundCategory]);

    useEffect(() => {
        filterRef.current.fund_type = fundType;
    }, [fundType]);

    useEffect(() => {
        filterRef.current.amc = amc;
    }, [amc]);

    useEffect(() => {
        filterRef.current.rating = rating;
    }, [rating]);

    useEffect(() => {
        filterRef.current.search_text = searchQuery;
    }, [searchQuery]);

    useEffect(() => {
        filterRef.current.theme = theme;
    }, [theme]);

    useEffect(() => {
        filterRef.current.filter_by = filterBy;
    }, [filterBy]);

    useEffect(() => {
        filterRef.current.filter_by_tag = quickInvestTagName;
    }, [quickInvestTagName]);

    useEffect(() => {
        filterRef.current.category_name = categoryName;
    }, [categoryName]);

    useEffect(() => {
        filterRef.current.sort_by = sortBy;
    }, [sortBy]);


    // this is run when we navigate here from header filter data present in the route.
    useEffect(() => {
        filterRef.current = {};
        if (location.state !== null) {
            const filters = location.state;
            setPageNumber(1);
            setInvestmentAmount(filters.minimum_investment);
            setFundCategory(filters.fund_category);
            console.log('filet', filters.fund_category);
            setFundType(filters.fund_type);
            setAmc(filters.amc);
            setTheme(filters.theme);
            setRating(filters.rating);
            setSearchQuery(filters.search_text);
            setFilterBy(filters.filter_by);
            if (filters.filter_by_tag !== undefined) {
                setQuickInvestTagName(filters.filter_by_tag);
            }
            if (filters.category_name !== undefined) {
                setCategoryName(filters.category_name);
            }
            setFundList([]);
            // Get selected filters count
            _getActiveFilter(filters.minimum_investment,
                filters.fund_category,
                filters.fund_type,
                filters.amc,
                filters.theme,
                filters.rating,
                filters.filter_by
            );
            // if (location.state.current === location.pathname) {
            setInitialRenderStatus(true);
            // }

            // filterRef.current.filter_enabled = true;
            filterRef.current.funds = [];
            preloadFunds.current = false;
        }
    }, [location.state]);

    // after _getFilterData() function is run, we come to know whether we are in search flow or normal flow.
    // only in search flow this runs
    useEffect(() => {
        if (initialRender === true) {
            _getFundList(sortBy); // API trigger from Search flow

            setInitialRenderStatus(false);
        }
    }, [initialRender])


    // this is run whenever we do any sort of filtering from explore filter or page number is changed.
    useEffect(() => {
        if (preloadFunds.current == false) {
            if (filterRender === true) {
                _getFundList(sortBy);
            } else if (pageNumber > 1) {
                _getFundList(sortBy, true); // pagination API trigger
            }
        }
        filterRef.current.page_num = pageNumber;
    }, [filterRender, pageNumber]);

    // hide order filter if show header filter
    useEffect(() => {
        if (HEADER_FILTER === true && showFilter === true) {
            setShowFilter(false);
        }
    }, [HEADER_FILTER]);

    useEffect(() => {
        if (showFilter === true) {
            // document.body.style.overflow = 'hidden';
            // document.body.style.height = '100vh';
            window.scrollTo({
                behavior: "smooth",
                top: 100
            });

        } else {
            document.body.removeAttribute("style");
        }
    }, [showFilter]);

    useEffect(() => {
        const handleScroll = (event) => {
            if (showFilter === true) {

                event.preventDefault();
                window.scrollTo({
                    behavior: "instant",
                    top: 100
                });

            }
        };

        // Add event listener when component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showFilter]);

    // listener for getting mf investment data
    useEffect(() => {
        if (Object.keys(showInvestmentModal).length !== 0) {
            if (USER_DATA.kyc_step < 11) {
                toast.dismiss();
                toast.error("Complete KYC to start investing", {
                    type: 'error'
                });
                setShowInvestModal({});
                return;
            }
            const { date } = _getDayOfMonth();
            _getMFInvestmentSummary(showInvestmentModal.id, true, date)
                .then((response) => {
                    setMfInvestmentData(response);
                    setMountInvestModal(true)
                })
                .catch((response) => {
                    toast.error(response, {
                        type: "error",
                    });
                });

        }
    }, [showInvestmentModal]);


    useEffect(() => {
        filterRef.current.funds = fundList;
        if (filterRef.current.filter_by === undefined) {
            filterRef.current.filter_by_tag = "";
        }
        if (filterRef.current.fund_category === undefined) {
            filterRef.current.category_name = "";
        }
        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });

    }, [fundList]);

    // api call for when search query is removed after clicking the close icon on the chip
    useEffect(() => {

        if (searchQueryChange === true) {
            _getFundList(sortBy);
        }
    }, [searchQueryChange])

    // listener for opening invest modal
    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    // Investment modal
    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    const _scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // handler for sort drop down menu
    const _handleChangeSort = (value) => {
        preloadFunds.current = false;
        setLastItemStatus(false);
        setFilterRender(true);
        setLoader(true);
        setSortBy(value);
        setSearchQuery("");
        setPageNumber(1);
        _scrollToTop();
    }

    // navigate to explore
    const _handleFundClick = (row) => {
        // filterRef.current.search_text = "";
        filterRef.current.filter_enabled = true;

        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });

        navigator("/explore/fund", {
            state: {
                fund_id: row.id,
            }
        });
    }

    // hide filter
    const _handleToggleFilter = () => {
        Store.dispatch({
            type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
            payload: false
        });
        setShowFilter(!showFilter);
    }

    // handler for page change
    const _handlePageNumber = () => {
        preloadFunds.current = false;
        filterRef.current.page_num = pageNumber + 1;
        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });

        setPageNumber(prevCount => prevCount + 1);

    }

    // handler for filter data
    const _handleFilterData = (mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by, status) => {

        if (status == true) { // customer selected "Apply Filters"
            preloadFunds.current = false;
            setLoader(true);
            setFundList([]);
            setInvestmentAmount(mia);
            setFundCategory(fund_category);
            setFundType(fund_type);
            setAmc(selected_amc);
            setTheme(selected_theme);
            setRating(rating);
            setFilterBy(filter_by);
            setFilterRender(true);
            // setSearchQuery("");
            setPageNumber(1);
            setLoader(true);
            setLastItemStatus(false);
            _getActiveFilter(mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by);
        }
        setShowFilter(false);
        _scrollToTop();
    }

    // get number of filters selected
    function _getActiveFilter(mia, fund_category, fund_type, selected_amc, selected_theme, rating, filter_by) {
        const filters = {
            mia,
            fund_category,
            fund_type,
            selected_amc,
            selected_theme,
            rating,
            filter_by
        };
        const selected_filters = Object.values(filters).filter(value => {
            if (Array.isArray(value)) {
                // Check if the array contains null values
                if (value.includes(null)) {
                    return false; // Exclude arrays with null values
                } else {
                    return value.some(item => item !== null);
                }
                // Check if the array contains non-null values

            } else {
                // Check if the value is not null
                return value !== null;
            }
        });
        setFiltersSelected(selected_filters.length);
    }

    const _handleMenuToggle = (isOpen) => {
        setIsMenuOpen(isOpen);
    };

    const _handleRemoveQuery = () => {
        setSearchQuery("");
        setSearchQueryChange(true);
        setPageNumber(1);
        let state = location.state;
        if (state !== null) {
            state.search_text = '';
            location.state = { ...state };
        }

        filterRef.current.search_text = "";
        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });
    }

    const _handleRemoveQuickInvestTag = () => {
        setQuickInvestTagName("");
        setPageNumber(1);
        setFilterBy(null);
        let state = location.state || {};
        if (Object.keys(state).length !== 0) {
            state.filter_by = null;
            state.filter_by_tag = "";
        }
        else {
            state = {
                minimum_investment: null,
                fund_category: [null],
                fund_type: [null],
                amc: [null],
                theme: [null],
                rating: null,
                search_text: '',
                filter_by: null,
                filter_by_tag: "",
                category_name: "",
                sort_by: { value: 5, label: 'Returns - High to Low' },
                page_num: 1
            };
        }

        location.state = { ...state };

        setLoader(true);
        filterRef.current.filter_by = null;
        filterRef.current.filter_by_tag = "";
        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });
        // navigator('/explore', state, {replace: true})
    }

    const _handleRemoveCategory = () => {
        setCategoryName("");
        setFundCategory([null]);
        setPageNumber(1);
        let state = location.state || {};
        if (Object.keys(state).length !== 0) {
            state.fund_category = [null];
            state.category_name = "";
        } else {
            state = {
                minimum_investment: null,
                fund_category: [null],
                fund_type: [null],
                amc: [null],
                theme: [null],
                rating: null,
                search_text: '',
                filter_by: null,
                filter_by_tag: "",
                category_name: "",
                sort_by: { value: 5, label: 'Returns - High to Low' },
                page_num: 1
            };
        }
        location.state = { ...state };
        setLoader(true);
        filterRef.current.fund_category = [null];
        filterRef.current.category_name = "";
        Store.dispatch({
            type: "SELECTED_MF_FILTERS",
            payload: filterRef.current
        });
    }


    // API - Get fund list
    const _getFundList = (sort_by, page_data_status = false) => {
        if (pageNumber === 1) {
            setLoader(true);
        }

        let url = 'mf-data/list';
        let payload = JSON.stringify({
            amc: amc.filter(item => item && item.id !== null),
            category: fundCategory.filter(item => item && item.id !== null),
            type: fundType.filter(item => item && item.id !== null),
            theme: theme.filter(item => item && item.id !== null),
            min_investment_filter_id: investmentAmount,
            sort_by: sort_by.value,
            query: searchQuery,
            filter_by: filterBy,
            rank: rating,
            page_num: pageNumber,
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                // page_data_status= true means when page number is more than 1 thus we append the data.
                if (page_data_status === true) {
                    if (response.data.length !== 0) {
                        setFundList([...memoizedData.fundList, ...response.data]);
                        // const temp = [...fundList, ...response.data]
                        if (response.data.length >= 12 && response.total_no_of_records > 12) {
                            setLastItemStatus(false);
                        } else {
                            setLastItemStatus(true);
                        }

                    }
                    else {
                        setLastItemStatus(true);
                    }
                }
                // page_data_status=false means page number is 1 thus first time run so store the data
                else {
                    if (response.data.length !== 0) {
                        setFundList(response.data);
                        if (response.data.length >= 12 && response.total_no_of_records > 12) {
                            setLastItemStatus(false);
                        } else {
                            setLastItemStatus(true);
                        }

                    }
                    else {
                        setLastItemStatus(true);
                    }
                }
                // since data is present in either of case then potentially there can be more data present when clicked on view more
                // so this is not the last item
                // setLastItemStatus(false);
            } else {
                // page_data_status =false means no data is present with the requested filter data
                if (page_data_status === false) {
                    setFundList([]);
                }
                // since no data is present anymore, irrespective of page number we know it is last time this hide the view more button
                setLastItemStatus(true);
            }
            setSearchQueryChange(false);
            setLoader(false);
            setFilterRender(false);

            Store.dispatch({
                type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
                payload: false
            });
        })
    }

    // API - Get MF filter data
    const _getFilterData = () => {
        let url = "mf-data/filter-data";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFilterData(response.data);
                let reversed = response.data.sort.reverse();
                const sort_by_data = reversed.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setSortByData(sort_by_data);
                setSortBy(sort_by_data[0]);

                // here we check whether its the initial run from search flow or normal flow and then call _getFundList() accordingly.
                if (location.state !== null || ACTIVE_MF_FILTERS.filter_enabled == true) {
                    if (location.state !== null) {
                        setInvestmentAmount(location.state.minimum_investment);
                        setFundCategory(location.state.fund_category);
                        setFundType(location.state.fund_type);
                        setAmc(location.state.amc);
                        setTheme(location.state.theme);
                        setRating(location.state.rating);
                        setSearchQuery(location.state.search_text);
                        setFilterBy(location.state.filter_by);
                        if (location.state.filter_by_tag !== undefined) {
                            setQuickInvestTagName(location.state.filter_by_tag);
                        }

                        if (location.state.category_name !== undefined) {
                            setCategoryName(location.state.category_name);
                        }

                    } else {
                        setInvestmentAmount(ACTIVE_MF_FILTERS.minimum_investment == undefined ? null : ACTIVE_MF_FILTERS.minimum_investment);
                        setFundCategory(ACTIVE_MF_FILTERS.fund_category == undefined ? [null] : ACTIVE_MF_FILTERS.fund_category);
                        setFundType(ACTIVE_MF_FILTERS.fund_type == undefined ? [null] : ACTIVE_MF_FILTERS.fund_type);
                        setAmc(ACTIVE_MF_FILTERS.amc == undefined ? [null] : ACTIVE_MF_FILTERS.amc);
                        setTheme(ACTIVE_MF_FILTERS.theme == undefined ? [null] : ACTIVE_MF_FILTERS.theme);
                        setRating(ACTIVE_MF_FILTERS.rating == undefined ? null : ACTIVE_MF_FILTERS.rating);
                        setSearchQuery(ACTIVE_MF_FILTERS.search_text == undefined ? "" : ACTIVE_MF_FILTERS.search_text);
                        setFilterBy(ACTIVE_MF_FILTERS.filter_by == undefined ? null : ACTIVE_MF_FILTERS.filter_by);
                        setQuickInvestTagName(ACTIVE_MF_FILTERS.filter_by_tag == undefined ? "" : ACTIVE_MF_FILTERS.filter_by_tag);
                        setCategoryName(ACTIVE_MF_FILTERS.category_name === undefined ? "" : ACTIVE_MF_FILTERS.category_name);
                        setFundType(ACTIVE_MF_FILTERS.fund_type == undefined ? [null] : ACTIVE_MF_FILTERS.fund_type);
                        if (ACTIVE_MF_FILTERS.sort_by && ACTIVE_MF_FILTERS.sort_by.value != undefined) {
                            setSortBy(ACTIVE_MF_FILTERS.sort_by);
                        }
                        // Get selected filters count
                        _getActiveFilter(
                            ACTIVE_MF_FILTERS.minimum_investment == undefined ? null : ACTIVE_MF_FILTERS.minimum_investment,
                            ACTIVE_MF_FILTERS.fund_category == undefined ? [null] : ACTIVE_MF_FILTERS.fund_category,
                            ACTIVE_MF_FILTERS.fund_type == undefined ? [null] : ACTIVE_MF_FILTERS.fund_type,
                            ACTIVE_MF_FILTERS.amc == undefined ? [null] : ACTIVE_MF_FILTERS.amc,
                            ACTIVE_MF_FILTERS.theme == undefined ? [null] : ACTIVE_MF_FILTERS.theme,
                            ACTIVE_MF_FILTERS.rating == undefined ? null : ACTIVE_MF_FILTERS.rating,
                            ACTIVE_MF_FILTERS.filter_by == undefined ? null : ACTIVE_MF_FILTERS.filter_by
                        );
                    }


                    if (ACTIVE_MF_FILTERS.funds.length != 0) {
                        if (location.state === null || (location.state && location.state.filter_by_tag !== "" && location.state && location.state.category_name !== "")) {
                            setFundList(ACTIVE_MF_FILTERS.funds);
                        }

                        // setFilterBy(ACTIVE_MF_FILTERS.filter_by == undefined ? null : ACTIVE_MF_FILTERS.filter_by);
                        if (ACTIVE_MF_FILTERS.page_num !== undefined) {
                            setPageNumber(ACTIVE_MF_FILTERS.page_num);
                        }
                        // if (location.state)
                        if (location.state === null) {
                            setLoader(false);
                        }

                        preloadFunds.current = true;
                    } else {
                        // setPageNumber(1);
                        setFundList([]);
                        setInitialRenderStatus(true);
                    }

                } else {
                    _getFundList(sort_by_data[0]);
                }
            } else {
                // Disable the button
            }
            // clearing the state two avoid persisting the search data on refresh

        });
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        if (id === null) {
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                // making payment success modal status as processing in case of netbanking and made instant payment
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })

    }

    const _handleSearchChange = (text) => {
        setSearchQuery(text)
    }

    function _removeTableFocus() {
        document.body.style.overflow = 'auto';
    }

    return (
        <Fragment>
            <div className="e-page-container margin-80px-bottom e-explore" id="explore-page">

                {/* Header */}
                <InvestmentHeader searchText={searchQuery}
                    onSearchChange={null} />

                <BreadCrumb data={PAGE_NAVIGATION} />

                {/* For preload the image */}
                <img src={NoData} className="d-none" />

                <div className="row">
                    <div className="col-12">
                        <div className="d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center flex-wrap mb-4 margin-20px-top"
                            id="filter-section">
                            {/* title */}
                            <div className="d-sm-flex align-items-center gap-16px">
                                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-0">Explore mutual fund schemes</p>
                                {
                                    searchQuery.length !== 0 &&
                                    <div className="d-flex align-items-center gap-8px p-2 bg-aero-blue e-search-chip border-radius-12px border-all border-bright-gray w-max-content">
                                        <p className="mb-0 text-transform-capitalize e-poppins-semi-bold white-space-nowrap overflowX-hidden">{searchQuery}</p>
                                        <Icon icon="close-without-circle"
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={_handleRemoveQuery} />
                                    </div>
                                }
                                {
                                    quickInvestTagName !== "" &&
                                    <div className="d-flex align-items-center gap-8px p-2 bg-aero-blue e-search-chip border-radius-12px border-all border-bright-gray w-max-content">
                                        <p className="mb-0 text-transform-capitalize e-poppins-semi-bold white-space-nowrap overflowX-hidden">{quickInvestTagName}</p>
                                        <Icon icon="close-without-circle"
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={_handleRemoveQuickInvestTag} />
                                    </div>
                                }
                                {
                                    categoryName !== "" &&
                                    <div className="d-flex align-items-center gap-8px p-2 bg-aero-blue e-search-chip border-radius-12px border-all border-bright-gray w-max-content">
                                        <p className="mb-0 text-transform-capitalize e-poppins-semi-bold white-space-nowrap overflowX-hidden">{categoryName}</p>
                                        <Icon icon="close-without-circle"
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={_handleRemoveCategory} />
                                    </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex  gap-16px mt-sm-0 mt-3 e-explore-filter-mob">
                                        <div className="d-flex justify-content-sm-end justify-content-between w-100 gap-8px align-items-center">
                                            {/* sort */}
                                            <SelectDropDown value={sortBy}
                                                options={sortByData}
                                                isOpen={isMenuOpen}
                                                placeholder="Sort by : "
                                                onMenuToggle={_handleMenuToggle}
                                                onSelectChange={_handleChangeSort} />
                                            {
                                                isMenuOpen === true &&
                                                <div className="position-fixed w-100 z-index-1 h-100 left-0 top-0 cursor-default"
                                                    onClick={() => setIsMenuOpen(false)}></div>
                                            }

                                            {/* explore filter*/}
                                            <div className="position-relative">
                                                {
                                                    filtersSelected > 0 && (
                                                        <p className="color-white border-radius-100px e-poppins-bold e-font-12 line-height-12px e-badge position-absolute mb-0 bg-sea-green d-flex justify-content-center align-items-center">
                                                            {filtersSelected}
                                                        </p>
                                                    )
                                                }
                                                <div className={`border-radius-6px border-all bg-white  d-flex align-items-center z-index-10 ${filtersSelected > 0 ? "border-sea-green" : "border-bright-gray"}`}
                                                    onClick={_handleToggleFilter}>
                                                    <p className="color-charleston-green e-poppins-medium e-font-14 line-height-24px mb-0 p-2 pe-1 cursor-pointer">Filter</p>
                                                    <img src={require('../../Assets/Images/explore/filter.png')}
                                                        alt="Filter"
                                                        width={24}
                                                        height={24}
                                                        className="cursor-pointer me-2" />

                                                </div>

                                                {
                                                    showFilter === true &&
                                                    <Fragment>
                                                        {console.log('fun', fundCategory)}
                                                        <ExploreFilterPopup data={filterData}
                                                            investmentAmount={investmentAmount}
                                                            fundCategory={fundCategory}
                                                            fundType={fundType}
                                                            amc={amc}
                                                            theme={theme}
                                                            rating={rating}
                                                            filterBy={filterBy}
                                                            close={_handleToggleFilter}
                                                            applyFilter={_handleFilterData} />

                                                        <div className="position-fixed w-100 z-index-10 h-100 left-0 top-0 cursor-default"
                                                            onClick={() => setShowFilter(false)}>
                                                        </div>
                                                    </Fragment>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loader === false && 
                        <div className="col-12 text-end mt-2 e-table-scroll-indicator d-sm-block d-none">
                            <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                    width='24px'
                                    height='24px'
                                    className='e-rotate-gif' />
                                Scroll horizontally for view more  information</p>
                        </div>
                    }

                </div>

                {
                    loader === true ?
                        <CustomLoader />
                        :
                        <Fragment>

                            {
                                fundList.length == 0 ?
                                    <>
                                        <div className="col-12 text-center margin-88px-top">
                                            <EmptyScreen image={NoData}
                                                title="No result found for selected filter"
                                                description=" " />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div id="table-wrap"
                                            className="e-fund-table-wrapper"
                                            onMouseLeave={_removeTableFocus}>
                                            {
                                                loader === false &&
                                                <FundTable data={memoizedData.fundList}
                                                    onSelect={_handleFundClick}
                                                    openModal={(data) => setShowInvestModal(data)} />
                                            }

                                        </div>
                                        {/* view more link */}
                                        {
                                            lastItemReached === false &&
                                            <div className="d-flex justify-content-center align-items-center w-100 mt-3"
                                                onClick={() => _handlePageNumber()}>
                                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                    View more
                                                </p>
                                                {
                                                    loader === false ?
                                                        <Icon icon="right-arrow"
                                                            size={24}
                                                            className="e-view-pending cursor-pointer" />
                                                        :
                                                        <img src={require("../../Assets/Images/loader.gif")}
                                                            alt="data loader"
                                                            width={50} />
                                                }
                                            </div>
                                        }
                                    </>
                            }
                        </Fragment>
                }
            </div>

            {/* footer */}
            <Footer />

            {
                mountInvestModal === true && (
                    <InvestGoalModal data={showInvestmentModal}
                        mfInvestmentData={mfInvestmentData}
                        close={() => {
                            setShowInvestModal({})
                            setMfInvestmentData({})
                            setMountInvestModal(false)
                        }}
                        showInProcessModal={_openMfInProcessModal}
                        getPaymentStatus={_getPaymentStatus} />
                )
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        type={isNextMonth === true ? 5 : 1}
                        close={() => setOpenPaymentStatusModal(false)} />
                )
            }
            {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }
        </Fragment>
    )
}

export default Explore;