/*
 *   File :  invite-family.js
 *   Author URI : https://evoqins.com
 *   Description : UI component which allows the existing Master Trsut user to invite or link another Master Trsut account to the Family segment.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

import Icon from "../Icon/icon";
import { CustomTextInput } from "../Form-elements";
import { GradientButton } from "../CTA";

import style from "../../Styles/Component/invite-family.module.scss"

const InviteMemberModal = (props) => {

    // state variables to store data
    const [mastertrustId, setmastertrustId] = useState("");
    const [mastertrustIdError, setmastertrustIdError] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [panNumberError, setPanNumberError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("invite-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    // input fields handler
    const _handleInputField = (type, value) => {
        switch (type) {
            case 1:
                setmastertrustId(value);
                setmastertrustIdError("");
                break;
            case 2:
                setPanNumber(value);
                setPanNumberError("");
                break;
        }
    }

    // validation handler
    const _handleValidation = () => {
        let valid = true;
        if (mastertrustId.length < 3) {
            setmastertrustIdError("Enter valid master trust id")
            valid = false
        }
        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
            valid = false;
            setPanNumberError("Enter valid PAN number");
        }
        if (valid === true) {
            document.getElementById("modal-close").dispatchEvent(new Event("click"));
            props.sendInvite(mastertrustId, panNumber);
        }
    }

    return (
        <div className={`modal fade ${style.e_invite_modal}`}
            id="invite-modal"
            tabIndex="-1"
            aria-labelledby="invite-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-lr padding-32px-top pb-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">Invite family member</p>
                        <Icon icon="close"
                            id="modal-close"
                            data-bs-dismiss="modal"
                            size={24}
                            className="cursor-pointer e-modal-close-btn" />
                    </div>
                    <div className="row margin-42px-top">
                        <div className="col-6">
                            {/* input field to enter master trust id */}
                            <CustomTextInput value={mastertrustId}
                                label="Add mastertrust ID"
                                postfix="*"
                                error={mastertrustIdError}
                                handleChange={(e) => _handleInputField(1, e)} />
                        </div>
                        <div className="col-6">
                            {/* input field to enter pan number */}
                            <CustomTextInput value={panNumber}
                                label="PAN number"
                                postfix="*"
                                inputClass="text-uppercase"
                                pan={true}
                                error={panNumberError}
                                handleChange={(e) => _handleInputField(2, e)} />
                        </div>
                    </div>
                    <p className="color-jett-black e-poppins-regular e-font-14 margin-32px-bottom mt-4">We will send out an invitation RSVP to the concern person email/phone.
                        Upon approval the user details will be updated on your profile.</p>
                    <GradientButton label="Send invite"
                        className="e-font-16 padding-12px-tb mx-5"
                        handleClick={_handleValidation} />
                </div>
            </div>
        </div>
    )
}

export default InviteMemberModal