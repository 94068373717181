/*
 *   File : gradient.js
 *   Author URI : https://evoqins.com
 *   Description : Gradient button for site
 *   Integrations : null
 *   Version : v1.1
 */

import style from "../../Styles/Component/gradient-btn.module.scss";

const GradientButton = (props) => {

    return (
        // Gradient button
        <button 
        className={props.disabled === false ?
            `${style.e_active_btn} ${props.className}  e-primary-font-medium e-font-sm-14-16 bg-prime-gradient color-white border-radius-6px no-border cursor-pointer`
            : `${style.e_inactive_btn} ${props.className}  e-primary-font-medium e-font-sm-14-16 bg-prime-gradient color-white border-radius-6px no-border`}
            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-loading={`${props.loading}`}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.handleClick}
        >
            <span className="position-relative d-flex justify-content-center">
                {props.label ? props.label : "Submit"}
                {
                    props.loading === true && (
                        <img src={require('../../Assets/Images/mt-wealth-button-loader.gif')} 
                            className={style.loader_gif}/>
                    )
                }
            </span>
        </button>
    );
};

GradientButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default GradientButton;
