/*
 *   File : otp-input.js
 *   Author : https://evoqins.com
 *   Description : component used in the platform for getting OTP/PIN in the platform
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useEffect, useState, memo, useRef, Fragment } from "react";

import style from "../../Styles/Component/otp-input.module.scss"

export default memo(OTPInput);
function OTPInput(props) {

    const otpRef = useRef(null);

    const [pinString, setPinString] = useState(props.pinString);
    const [pin, setPin] = useState([]);
    const [error, setError] = useState("");
    const [focusedInput, setFocusedInput] = useState(false);


    useEffect(() => {
        setPinString(props.pinString);
        if (props.pinString.length == 0) {
            setPin([]);
        }
        
    }, [props.pinString]);

    useEffect(()=>{
        if (props.autoFocus == true) {
            setTimeout(() => {
                otpRef.current.focus();
            }, 500);
        }
    },[]);

    useEffect(() => {
        if (props.disabled === true) {
            setFocusedInput(false);
        }
    }, [props.disabled])

    useEffect(() => {
        setError(props.pinError);
    }, [props.pinError]);

    const _handleInput = (value) => {
        let input_field = value.target.value;
        if (input_field.length <= (props.type === 1 ? 4 : 6)) {
            setPinString(input_field);
            const characters = [];

            for (let i = 0; i < input_field.length; i++) {
                characters.push(input_field.charAt(i));
            }
            setPin(characters);
            props.handleChange(input_field);
        }
    };

    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const is_enter_key = key_code === 13;
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code) && !is_enter_key) {
            e.preventDefault();
        }
    };

    return (
        <div className={`position-relative ${style.e_pin} ${props.className}`}>
            {
                props.title &&
                <p className={`color-black e-poppins-medium e-font-14 mb-2 text-start ${props.titleClass}`}>{props.title} </p>
            }

            <input type='text'
                ref={otpRef}
                id={props.id}
                value={pinString}
                spellCheck="false"
                autoComplete="off"
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                className='mb-0 color-white w-100'
                onFocus={() => setFocusedInput(true)}
                onBlur={() => setFocusedInput(false)}
                onKeyDown={_validateNumber}
                onChange={_handleInput} />

            <label className={`d-flex justify-content-center gap-10px position-absolute  left-0 ${!props.title && "top-0"} `} htmlFor={props.id}>
                <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true ? 'border-primary-color' : 'border-light-silver'}`}>
                    {pin.length !== 0 ? "\u2022" : ""}
                </div>
                <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 2 ? 'border-primary-color' : 'border-light-silver'}`}>
                    {pin.length >= 2 ? "\u2022" : ""}
                </div>
                <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 3 ? 'border-primary-color' : 'border-light-silver'}`}>
                    {pin.length >= 3 ? "\u2022" : ""}
                </div>
                <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 4 ? 'border-primary-color' : 'border-light-silver'}`}>
                    {pin.length >= 4 ? "\u2022" : ""}
                </div>
                {
                    props.type === 2 &&
                    <Fragment>
                        <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 5 ? 'border-primary-color' : 'border-light-silver'}`}>
                            {pin.length >= 5 ? "\u2022" : ""}
                        </div>
                        <div className={`color-black e-poppins-regular e-font-16 line-height-20px border-radius-8px  border-all d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 6 ? 'border-primary-color' : 'border-light-silver'}`}>
                            {pin.length >= 6 ? "\u2022" : ""}
                        </div>
                    </Fragment>
                }
            </label>
            <p className="color-red e-font-12 e-poppins-regular mb-0 position-absolute ">{error}</p>
        </div>
    )
}

OTPInput.defaultProps = {
    pinString: '',
    autoFocus: false,
    disabled: false,
    type: 1
}