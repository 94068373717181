/*
 *   File : outline-button.js
 *   Author URI : https://evoqins.com
 *   Description : Outline button for site
 *   Integrations : null
 *   Version : v1.1
 */

import Icon from "../Icon/icon";

import style from "../../Styles/Component/outline-button.module.scss";

const OutlineButton = (props) => {

    return (
        // Primary button
        // <button className={`${style.e_outline_btn} ${props.className} bg-pale-steel-blue border-all border-primary-color line-height-24px e-poppins-medium padding-6px-tb padding-10px-lr color-primary-color text-center border-radius-8px cursor-pointer ${props.disabled === true && style.e_disabled}`}
        <button className={
            props.disabled === false ?
                `${style.e_outline_btn} ${props.className} bg-pale-steel-blue border-all border-primary-color line-height-24px e-primary-font-medium padding-6px-tb padding-10px-lr color-primary-color text-center border-radius-8px cursor-pointer`
                :
                `${style.e_inactive_btn} ${props.className} bg-pale-steel-blue border-all border-primary-color line-height-24px e-primary-font-medium padding-6px-tb padding-10px-lr color-primary-color text-center border-radius-8px `}
            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            data-loading={`${props.loading}`}
            onClick={props.handleClick}>
            <span className="position-relative d-flex justify-content-center">
            {props.label ? props.label : "Submit"}
                {
                    props.loading === true && (
                        <img src={require('../../Assets/Images/button-loader.gif')}
                            className={style.loader_gif}/>
                    )
                }
                {
                    props.icon &&
                    <Icon icon={props.icon}
                        size={props.iconSize}
                        className={props.iconClass} />
                }
            </span>
        </button>
    );
};

OutlineButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default OutlineButton;
