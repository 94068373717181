/*
 *   File :  portfolio-funds.js
 *   Author URI : www.evoqins.com
 *   Description : Popup component which allows the user to make any transactions from the invested funds or goals.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 23-10-2023
 */

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { DataTable } from "../Form-elements";
import Icon from "../Icon/icon";
import { EmptyScreen } from "../Other";
import { PortfolioOptions } from "../Popups";
import { _getDayOfMonth, useClickOutside } from "../../Helper";
import APIService from "../../Service/api-service";
import { FundRedeemModal, InvestGoalModal, PaymentStatusModal, SWPFundModal, STPFundModal, SwitchFundModal, MfInProcessStatus } from "../Modal";
import { _getMFInvestmentSummary, _getMFDetails } from '../../Helper/api';

const PORTFOLIO_STYLES = {
    headRow: {
        style: {
            fontSize: "16px",
            lineHeight: "18px"
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    rows: {
        style: {
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
        }
    }
};

const PORTFOLIO_STYLES_MOBILE = {
    headRow: {
        style: {
            paddingRight: "0px",
            fontSize: "14px",
            paddingBottom: "8px !important",
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    rows: {
        style: {
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
            '&:first-child': {
                padding: "0px 0 0px 0px !important"
            },
            '&:last-child': {
                padding: "0px 0px 0px 0 !important"
            },
        }
    }
};

const SUCCESS_MESSAGE = {
    title: 'Redeem successful',
    message: 'You have successfully redeemed'
}

const FAILED_MESSAGE = {
    title: 'Redeem failed',
    body: 'We regret to inform you that your redemption was failed.'
}

const SUCCESS_SWP = {
    title: 'SWP created',
    message: 'Your SWP has been successfully created'
}

const SWP_FAILED = {
    title: 'SWP failed',
    message: 'We regret to inform you that your recent SWP has failed'
}

const PortfolioFundsTable = (props) => {

    const navigator = useNavigate();
    const { date } = _getDayOfMonth();
    const optionsRef = useRef();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [showInfo, setShowInfo] = useState(null);
    const [showOptions, setShowOptions] = useState({});
    const [investedFunds, setInvestedFunds] = useState([]);
    const [fundRedeemData, setFundRedeemData] = useState({});
    const [showInvestModal, setInvestModalStatus] = useState(false);
    const [mfData, setMFData] = useState({});
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [investmentType, setInvestmentType] = useState(1);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [showSWPModal, setShowSWPModal] = useState(false);
    const [swpFund, setSWPFund] = useState({});
    const [showSTPModal, setShowSTPModal] = useState(false);
    const [stpFund, setSTPFund] = useState({});
    const [showSwitchModal, setShowSwitchModal] = useState(false);
    const [switchFund, setSwitchFund] = useState({});
    const [transactionType, setTransactionType] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useEffect(() => {
        setInvestedFunds(props.data);
    }, [props.data]);

    useClickOutside(optionsRef, () => {
        setShowOptions({});
        setSelectedRow(-1);
    });

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    const _sortFundName = (rowA, rowB, key) => {
        const valueA = String(rowA[key]).toLowerCase();
        const valueB = String(rowB[key]).toLowerCase();

        if (valueA > valueB) {
            return 1;
        }

        if (valueB > valueA) {
            return -1;
        }
        return 0;
    };

    useEffect(() => {
        if (Object.keys(fundRedeemData).length !== 0) {
            const modal = new Modal(document.getElementById("fund-redeem"), {});
            modal.show();
        }
    }, [fundRedeemData]);

    useEffect(() => {
        if (showInvestModal === true) {
            _openInvestGoalModal();
        }
    }, [showInvestModal]);

    useEffect(() => {
        if (showSWPModal === true) {
            _openSWPModal();
        }
    }, [showSWPModal]);

    useEffect(() => {
        if (showSTPModal === true) {
            _openSTPModal();
        }
    }, [showSTPModal]);

    useEffect(() => {
        if (showSwitchModal === true) {
            _openSwitchModal();
        }
    }, [showSwitchModal]);

    // open swp modal
    function _openSWPModal() {
        const modal = new Modal(document.getElementById("swp-fund"), {});
        modal.show();
    }

    function _openSTPModal() {
        const modal = new Modal(document.getElementById("stp-fund"), {});
        modal.show();
    }

    function _openSwitchModal() {
        const modal = new Modal(document.getElementById("switch-fund"), {});
        modal.show();
    }

    //to open invest goal modal
    const _openInvestGoalModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    };

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    const MF_COLUMNS = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            // width: windowWidth > 575 ? "max-content" : '450px',
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="padding-6px-all border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['fund_name']}
                            width={46}
                            height={46}
                            className="object-fit-contain" />
                    </div>
                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                        <div className="d-flex flex-wrap gap-8px ">
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px">
                                {row['category']}
                            </span>
                            <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise">{row['holding_type']}</span>
                            <span className="border-radius-16px color-primary-color e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-primary-color border-all bg-alice-blue w-50px text-center w-max-content min-width-50">{row['type']}</span>

                        </div>
                    </div>

                </div>
        },
        {
            name: 'Invested',
            selector: row => row['invested_amount'],
            sortable: true,
            width: "140px",
            cell: row =>
                <p className={`color-black e-poppins-medium e-font-14 line-height-18px mb-0 ${row['invested_amount'] < 0 ? "color-red" : "color-sea-green"}`}
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current',
            selector: row => row['current_amount'],
            sortable: true,
            width: "140px",
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Total units',
            selector: row => row['units'],
            width: "140px",
            sortable: true,
            cell: row => <p className={`color-black e-poppins-medium e-font-14 line-height-18px mb-0 ${row['units'] < 0 ? "color-red" : "color-sea-green"}`}
                onClick={() => _handleCardClick(row)}>{row['units']}</p>
        },
        {
            name: 'Returns',
            selector: row => row['returns'],
            width: "150px",
            sortable: true,
            cell: row =>
                <p className={`${row['returns'] < 0 ? "color-red" : "color-sea-green"
                    } e-inter-medium e-font-14 line-height-18px mb-0`}
                    onClick={() => _handleCardClick(row)}>
                    {
                        row['returns'] == 0 ? '-'
                            :
                            <>
                                ₹{row['returns'].toLocaleString("en-IN")}
                                ({row['returns_percentage'].toLocaleString("en-IN")}%)
                            </>

                    }

                </p>


        },
        {
            name: '',
            sortable: true,
            width: "24px",
            cell: (row, index) =>
                <div className="position-relative">
                    {
                        row['type'] === 'mt' &&
                        <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                            width={24}
                            height={24}
                            onClick={() => _showOptions(row, index)} />
                    }
                    {
                        selectedRow === index &&
                        <div ref={optionsRef}>
                            <PortfolioOptions data={row}
                                id={`option-id-${index}`}
                                redeem={() => _handleRedeem(row)}
                                invest={_handleInvest}
                                swp={() => _handleSWP(row)}
                                stp={() => _handleSTP(row)}
                                switch={() => _handleSwitch(row)} />
                        </div>
                    }

                </div>
        },
        {
            name: '',
            sortable: true,
            width: "24px",
            cell: (row, index) =>
                row['type'] !== "mt" &&
                <div className="position-relative cursor-pointer"
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="info"
                        size={24}
                        onMouseEnter={() => _showInfo(row.mf_id)}
                        onMouseLeave={_hideInfo} />
                    {
                        showInfo === row.mf_id &&
                        <span className="color-outer-space border-radius-8px border-all border-bright-gray bg-white e-poppins-regular e-font-12 line-height-14px p-2 position-absolute e-external-fund">
                            non-mt holding is only for tracking purposes and transaction is not allowed.
                        </span>
                    }
                </div>
        }
    ];

    const MF_COLUMNS_MOBILE = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: '60%',
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div>
                        <p className="color-black e-poppins-medium e-font-12 line-height-22px mb-2 bg-white ">{row['fund_name']}</p>
                        <div className="d-flex flex-wrap gap-8px ">
                            <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-8 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise">{row['holding_type']}</span>
                            <span className="border-radius-16px color-primary-color e-poppins-medium e-font-8 line-height-18px padding-6px-lr py-1 border-primary-color border-all bg-alice-blue w-50px text-center w-max-content min-width-50">{row['type']}</span>

                        </div>
                    </div>

                </div>
        },

        {
            name: 'Current(Invested)',
            selector: row => row['current_amount'],
            sortable: false,
            width: '35%',
            cell: (row, index) =>
                <div className="d-flex">
                    <div className="text-center">
                        <p className={`color-black e-poppins-semi-bold e-font-12 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}
                            onClick={() => _handleCardClick(row)}>
                            <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
                        <p className={`color-black e-poppins-medium e-font-12 line-height-18px mb-0 color-black`}
                            onClick={() => _handleCardClick(row)}>
                            <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
                        <p className={`${row['returns'] < 0 ? "color-red" : "color-sea-green"
                            } e-inter-medium e-font-12 line-height-18px mb-0`}
                            onClick={() => _handleCardClick(row)}>
                            {
                                row['returns'] == 0 ? '-'
                                    :
                                    <>
                                        ₹{row['returns'].toLocaleString("en-IN")}
                                        ({row['returns_percentage'].toLocaleString("en-IN")}%)
                                    </>

                            }

                        </p>
                    </div>
                    {/* <div className="d-flex h-100">
                        <div className="position-relative">
                            {
                                row['type'] === 'mt' &&
                                <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                                    width={18}
                                    height={18}
                                    onClick={() => _showOptions(row, index)} />
                            }
                            {
                                selectedRow === index &&
                                <div ref={optionsRef}>
                                    <PortfolioOptions data={row}
                                        id={`option-id-${index}`}
                                        redeem={() => _handleRedeem(row)}
                                        invest={_handleInvest}
                                        swp={() => _handleSWP(row)}
                                        stp={() => _handleSTP(row)}
                                        switch={() => _handleSwitch(row)} />
                                </div>
                            }

                        </div>
                    </div> */}
                </div>
        },
        {
            name: '',
            selector: row => row['current_amount'],
            sortable: false,
            width: '2%',
            cell: (row, index) =>
                <div className="position-relative h-100">
                    {
                        row['type'] === 'mt' &&
                        <div className="d-flex align-items-top h-100">
                        <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
                            width={18}
                            height={18}
                            onClick={() => _showOptions(row, index)} />
                        </div>
                    }
                    {
                        selectedRow === index &&
                        <div ref={optionsRef}>
                            <PortfolioOptions data={row}
                                id={`option-id-${index}`}
                                redeem={() => _handleRedeem(row)}
                                invest={_handleInvest}
                                swp={() => _handleSWP(row)}
                                stp={() => _handleSTP(row)}
                                switch={() => _handleSwitch(row)} />
                        </div>
                    }

                </div>
        }

    ];

    function _getPaymentStatus() {
        setPaymentStatus(true);
    }

    // API - track redeem drop off user
    function _saveRedeemUser(row) {
        let url = 'portfolio/save-redemption-request';
        let payload = JSON.stringify({
            id: row.id,
            is_goal: false
        });
        APIService(true, url, payload).then((response) => {
            console.log(response);
        })
    }

    // API - get redeem data 
    const _handleRedeem = (row) => {
        if (row.type === 'mt' && row.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            _saveRedeemUser(row);
            return;
        }

        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: showOptions.mf_id,
            units: showOptions.units,
            sip_date: date,
            transaction_type: 3
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setFundRedeemData(response.data);
                setShowOptions({});
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }
    // _getMFDetails
    // API - Get investment data 
    const _handleInvest = (selected_type, fund_data) => {
        setInvestmentType(selected_type);
        _getFundData();
    }

    // API - for get SWP detail
    const _handleSWP = (row) => {
        if (row.type === 'mt' && row.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(5);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: showOptions.mf_id,
            units: showOptions.units,
            sip_date: date,
            transaction_type: 5,
            generate_today: true,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSWPFund(response.data);
                setShowOptions({});
                setShowSWPModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    // API - for get STP detail
    const _handleSTP = (row) => {
        if (row.type === 'mt' && row.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(6);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: showOptions.mf_id,
            units: showOptions.units,
            sip_date: date,
            transaction_type: 6,
            generate_today: true,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSTPFund(response.data);
                setShowOptions({});
                setShowSTPModal(true);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    // API - for get Switch detail
    const _handleSwitch = (row) => {
        if (row.type === 'mt' && row.holding_type === 'Demat') {
            toast.dismiss();
            toast.error("Redemption is currently not allowed for demat mode. Please contact customer support for assistance. We are working to make it available soon", {
                type: "error"
            });
            return;
        }
        setTransactionType(4);
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: showOptions.mf_id,
            units: showOptions.units,
            sip_date: date,
            transaction_type: 4
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSwitchFund(response.data);
                setShowOptions({});
                setShowSwitchModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    const _showInfo = (id) => {
        setShowInfo(id)
    }

    const _hideInfo = () => {
        setShowInfo(null);
    }

    function _closeSWPModal() {
        setShowSWPModal(false);
    }

    function _closeSTPModal() {
        setShowSTPModal(false);
    }

    function _closeSwithModal() {
        setShowSwitchModal(false);
    }

    const _showOptions = (row, index) => {
        if (investedFunds.length - 1 === index) {
            let options = document.getElementById(`option-id-${index}`);
            console.log('optionss', options);
            if (options) {
                options.style.top = '0';
                options.style.right = '17px';
            }
        }
        setShowOptions(row);
        setSelectedRow(index);
    }

    useEffect(() => {
        setTimeout(() => {
            if (Object.keys(showOptions).length !== 0) {
                if (investedFunds.length > 0 && showOptions.mf_id === investedFunds[investedFunds.length - 1].mf_id) {
                    let index = investedFunds.length - 1;
                    let options = document.getElementById(`option-id-${index}`);
                    console.log('optionss', options, `option-id-${index}`);
                    if (options) {
                        options.style.top = '0';
                        options.style.right = '17px';
                    }
                }
            }
        }, 10);
    }, [showOptions]);

    const _handleCardClick = (row) => {
        if (row.type === 'non-mt') {
            return;
        }
        props.onClick(row.id);
    }

    const _handleRedeemModalClose = () => {
        setFundRedeemData({});
    }

    const _handleRedeemStatus = (type) => {
        console.log("FundRedeem", fundRedeemData)
        setFundRedeemData({});
        setOpenPaymentStatusModal(true);
        switch (type) {
            case 1:
                setPaymentMessage(SUCCESS_MESSAGE);
                setPaymentStatus(true);
                break;
            case 2:
                setPaymentMessage(FAILED_MESSAGE);
                setPaymentStatus(false);
                break;
        }
    }

    function _handleSWPtatus(type) {
        setSWPFund({});
        setSTPFund({});
        setSwitchFund({});
        setShowSWPModal(false);
        setShowSTPModal(false);
        setShowSwitchModal(false);
        setOpenPaymentStatusModal(true);
        switch (type) {
            case 1:
                setPaymentMessage(SUCCESS_SWP);
                setPaymentStatus(true);
                break;
            case 2:
                setPaymentMessage(SWP_FAILED);
                setPaymentStatus(false);
                break;
        }
    }


    // API -  Fund detail
    function _getFundData() {
        let url = 'mf-data/detail';
        let payload = JSON.stringify({
            id: showOptions.mf_id
        });

        APIService(true, url, payload)
            .then((response) => {
                if (response.status_code === 200) {
                    let result = response.data;
                    setMFData(result);
                    _getInvestmentData();
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error"
                    });
                }
            });
    };

    // API - MF investment data
    const _getInvestmentData = () => {

        _getMFInvestmentSummary(showOptions.mf_id, true, date)
            .then((response) => {
                setMfInvestmentData(response);
                setInvestModalStatus(true);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            });
    }

    const conditionalRowStyles = [
        {
            when: row => row.type === 'non-mt',
            style: {
                cursor: 'default', // Changed to correct syntax
            },
        },
    ];

    return (
        <>
            {
                investedFunds.length == 0 ?
                    <div className="col-12 text-center mt-5 mb-sm-0 mb-5 pb-sm-0 pb-5">
                        <EmptyScreen image={require('../../Assets/Images/nodata/sip.svg').default}
                            title={props.selectedFund === 2 ? "No external funds found" : "Not yet invested in mutual funds?"}
                            description="Invest in mutual funds for better returns"
                            buttonLabel="Explore fund"
                            onPress={() => navigator('/explore')} />
                    </div>
                    :
                    <div className="e-portfolio-funds e-mobile-table mt-sm-0 mt-2">
                        <DataTable columns={windowWidth > 576 ? MF_COLUMNS : MF_COLUMNS_MOBILE}
                            id="fund-table-portfolio"
                            data={investedFunds}
                            pagination={false}
                            customStyles={windowWidth > 576 ? PORTFOLIO_STYLES : PORTFOLIO_STYLES_MOBILE}
                            rowClick={_handleCardClick}
                            conditionalRowStyles={conditionalRowStyles} />
                    </div>
            }

            {
                Object.keys(fundRedeemData).length !== 0 ?
                    <FundRedeemModal data={fundRedeemData}
                        redeemStatus={_handleRedeemStatus}
                        close={_handleRedeemModalClose} />
                    :
                    null
            }

            {/* Investment modal */}
            {
                showInvestModal === true &&
                <InvestGoalModal data={mfData}
                    mfInvestmentData={mfInvestmentData}
                    selectedTab={investmentType}
                    isPortfolio={true}
                    close={() => {
                        setMfInvestmentData({})
                        setMFData({})
                        setInvestModalStatus(false);
                    }}
                    showInProcessModal={_openMfInProcessModal}
                    getPaymentStatus={_getPaymentStatus} />
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        message={paymentMessage}
                        type={paymentMessage !== null && 1}
                        transactionType={transactionType}
                        close={() => {
                            setPaymentMessage(null);
                            setPaymentStatus(false);
                            setTransactionType(null);
                            setOpenPaymentStatusModal(false);
                        }} />
                )
            }

            {
                showSWPModal === true && (
                    <SWPFundModal data={swpFund}
                        redeemStatus={_handleSWPtatus}
                        close={_closeSWPModal} />
                )
            }

            {
                showSTPModal === true && (
                    <STPFundModal data={stpFund}
                        redeemStatus={_handleSWPtatus}
                        close={_closeSTPModal} />
                )
            }

            {
                showSwitchModal === true && (
                    <SwitchFundModal data={switchFund}
                        redeemStatus={_handleSWPtatus}
                        close={_closeSwithModal} />
                )
            }
            {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }
        </>


    )
}

export default PortfolioFundsTable