/*
 *   File : api.js
 *   Author URI : https://evoqins.com
 *   Description : Helper function for commonly used APIs 
 *   Integrations : js-cookie
 *   Version : v1.1
 */

import Cookies from 'js-cookie';
import Store from '../Store';

import APIService from "../Service/api-service";

// Profile API
const _getProfile = () => {
    let url = "profile/get";
    return APIService(false, url).then((response) => {
        if (response.status_code === 200) {
            return response.data;
        } else {
            throw new Error(response.message);
        }
    });
};

// API get user name 
const _getUerName = () => {
    let url = 'auth/verify-auth-status';
    return APIService(false, url, null, 'refresh').then((response) => {
        if (response.status_code === 200) {
            return response.data;
        } else {
            throw new Error(response.message);
        }
    })
};

// API - Add or remove MF in watch list
const _addToWatchList = (id) => {
    return new Promise((resolve, reject) => {
        let url = 'mf-data/toggle-watchlist';
        let payload = JSON.stringify({
            mf_id: id
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.message);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
};



// Filter data for header navigation
const _getHeaderSearch = () => {
    let url = "mf-data/search-filter-data";
    return APIService(false, url).then((response) => {
        if (response.status_code === 200) {
            return response.data;
        } else {
            throw new Error(response.message);
        }
    });
};

const _mfAddToBasket = (id, amount) => {
    return new Promise((resolve, reject) => {
        let url = 'cart/add';
        let payload = JSON.stringify({
            mf_id: id,
            amount: amount
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _mfDeleteCart = (delete_items) => {
    return new Promise((resolve, reject) => {
        let url = 'cart/delete';
        let payload = JSON.stringify({
            mf_ids: delete_items,
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get portfolio details
const _getPortFolioDetail = (id, is_goal) => {
    return new Promise((resolve, reject) => {
        let url = 'portfolio/detail';
        let payload = JSON.stringify({
            id: id,
            is_goal: is_goal
        })

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// get cart data
const _mfGetCart = () => {
    return new Promise((resolve, reject) => {
        let url = "cart/get";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

// API - set goal
const _setGoal = (id, tenure, target, lumpsum, sip, step_sip, inflation_rate, available_corpus, name, investment_style_id, additional_data) => {
    return new Promise((resolve, reject) => {
        let url = 'goal/set';
        let payload = JSON.stringify({
            goal_type_id: id,
            tenure,
            target,
            lumpsum,
            sip,
            step_sip,
            inflation_rate,
            available_corpus,
            name,
            investment_style_id,
            additional_data
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - will return details of any goal with default data.
const _getGoalDetail = (id) => {
    return new Promise((resolve, reject) => {
        let url = 'goal/details';
        let payload = JSON.stringify({
            goal_id: id,
            is_invested: false
        })

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - will return details MF investment data.
const _getMFInvestmentSummary = (id, generate_today, date, mandate_id) => {
    return new Promise((resolve, reject) => {
        let url = 'mf/invest-data';
        let payload = JSON.stringify({
            id: id,
            generate_today: generate_today,
            sip_date: date,
            mandate_id
        })

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - Get details of selected MF
const _getMFDetails = (params) => {
    return new Promise((resolve, reject) => {
        let url = 'mf-data/detail';
        let payload = JSON.stringify(params)

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getInvestmentStyles = () => {
    return new Promise((resolve, reject) => {
        let url = 'general/get-investment-styles';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getNotificationCount = () => {
    return new Promise((resolve, reject) => {
        let url = 'notification/count';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getQueryTypes = () => {
    return new Promise((resolve, reject) => {
        let url = 'support/query-types';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _consent2FA = (type) => {
    return new Promise((resolve, reject) => {
        let url = '2FA/get-otp';
        let payload = JSON.stringify({
            type: type
        })

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.message);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });

}

// Get investment data for Goals
function _getGoalInvestmentSummary(id, generate_today, sip_date, mandate_id) {
    return new Promise((resolve, reject) => {
        let url = 'goal/invest-data';
        let payload = JSON.stringify({
            id,
            // generate_today,
            sip_date,
            mandate_id
        });
        APIService(true, url, payload).then((response) => {
            console.log('Invest data :: ', response)
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _getForgotPINOTP = (type) => {
    return new Promise((resolve, reject) => {
        let url = "auth/forgot-pin"
        let payload = JSON.stringify({
            refresh_token: Cookies.get('refreshToken')
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

const _createMandate = (bank_id, limit, end_date) => {
    return new Promise((resolve, reject) => {
        const parts = end_date.split('-');
        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];                   
        let url = 'profile/mandates/create';
        let payload = JSON.stringify({
            bank_id,
            limit,
            end_date: formatted_date
        });
        APIService(true, url, payload)
            .then((response) => {
                if (response.status_code === 200) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
    });
};

// API :: get kyc progress
const _getKycProfile = () => {
    let url = "kyc/get-data";
    return APIService(false, url).then((response) => {
        if (response.status_code === 200) {
            Store.dispatch({
                type: 'KYC_PROGRESS',
                payload: response.data
            });
            return response.data;
        } else {
            throw new Error(response.message);
        }
    });
};

// API - authenticate mandate
const _authenticateMandate = (mandate_id) => {
    return new Promise((resolve, reject) => {
        let url = 'profile/mandates/authenticate';
        let payload = JSON.stringify({
            mandate_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get mandate status
const _mandateStatus = (mandate_id) => {
    return new Promise((resolve, reject) => {
        let url = 'profile/mandates/callback';
        let payload = JSON.stringify({
            mandate_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response)
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    })
}

// API - mobile | email relations
function _getMobileOrEmailRelation () {
    return new Promise((resolve, reject) => {
        let url = 'auth/signup-relation';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const relation_options = response.data.map(({ id, name }) => ({
                    value: id,
                    label: name
                }));
                resolve(relation_options);
            }else{
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    })
}

export {
    _getProfile,
    _getHeaderSearch,
    _addToWatchList,
    _mfAddToBasket,
    _mfDeleteCart,
    _mfGetCart,
    _getPortFolioDetail,
    _setGoal,
    _getGoalDetail,
    _getUerName,
    _getMFInvestmentSummary,
    _getInvestmentStyles,
    _getNotificationCount,
    _getQueryTypes,
    _consent2FA,
    _getForgotPINOTP,
    _getGoalInvestmentSummary,
    _createMandate,
    _getMFDetails,
    _getKycProfile,
    _authenticateMandate,
    _mandateStatus,
    _getMobileOrEmailRelation
}
