/*
 *   File : filepicker.js
 *   Author : https://evoqins.com
 *   Description : Form element to upload a file from the user for doing KYC.
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useEffect, useRef } from "react";

import Icon from "../Icon/icon";


import style from "../../Styles/Component/gradient-btn.module.scss";

const CustomFilePicker = (props) => {

    const fileInputRef = useRef(null);

    useEffect(() => {
        _emptyFile();
    }, []);


    const _emptyFile = () => {
        const file_input = document.getElementById('file');
        file_input.addEventListener("click", function () {
            if (file_input.value) {
                file_input.value = null; // Clear the file input
            }
        });
    }

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const _handleFileChange = (e) => {
        // Handle file change logic here
        props.onSelectFile(e.target.value, e.target.files[0])
    };

    return (
        <div className="position-relative">
            <input
                type="file"
                id="file"
                accept={props.accept}
                ref={fileInputRef}
                onChange={_handleFileChange}
                className="d-none"
            />
            <div className={`border-radius-8px  border-gap bg-pale-baby-blue ps-3 pe-3 py-1 d-flex justify-content-between align-items-center cursor-pointer ${props.className} ${props.error ? 'border-red' : 'border-primary-color'}`}
                onClick={handleDivClick}>
                <div className="d-flex align-items-center gap-16px">
                    <img src={require("../../Assets/Images/profile/file-upload.png")} alt="File upload"
                        width={24}
                        height={24} />
                    <div >

                        <p className="color-jett-black e-poppins-regular e-font-14 mb-1 overflow-hidden h-22px">Select a file</p>
                        <p className="color-outer-space e-poppins-regular e-font-8 mb-0 overflow-hidden h-10px">JPG, PNG or PDF, file size no more than 5MB</p>
                    </div>
                </div>
                {
                    props.loading === true ? (
                        <span className={`${style.e_loader_ring} position-relative`}></span>
                    )
                        :
                        <p className="color-azure-blue e-poppins-regular e-font-8 mb-0 padding-12px-lr py-2 border-radius-4px border-all border-azure-blue bg-white white-space-nowrap">Select file</p>
                }
            </div>
            {
                props.error && (
                    <div className='position-absolute d-flex align-items-center mt-1 '>
                        <Icon icon="info-circle"
                            size={16}
                            className="me-1" />
                        <p className='color-red margin-block-end-0 e-font-12 '>
                            {props.error}
                        </p>
                    </div>
                )
            }
        </div>
    )
}

CustomFilePicker.defaultProps = {
    loading: false
}

export default CustomFilePicker